import { Command } from '@ckeditor/ckeditor5-core';
import { ObservableMixin, mix } from '@ckeditor/ckeditor5-utils';

class DeleteBlockCommand extends Command {
	constructor( editor: any ) {
		super( editor );
		// @ts-ignore
		this.set( 'isVisible', false );
	}

	public override execute( params: any ): void {
		// @ts-ignore
		this.isVisible = params.isVisible;
	}
}

// Make the command Observable.
mix( DeleteBlockCommand, ObservableMixin );

export default DeleteBlockCommand;

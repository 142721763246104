import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';

export default class SaveIndicatorUI extends Plugin {
	public init(): void {
		const editor = this.editor;

		editor.ui.componentFactory.add( 'saveIndicator', ( locale: any ) => {
			const view = new ButtonView( locale );
			view.set( {
				label: 'Saving...',
				isEnabled: true,
				isVisible: true,
				withText: true
			} );

			// Event fired when the 'execute' event is fired.
			view.on( 'execute', () => {
				/* Triggered when the button is clicked.
				You can perform custom action here or leave it empty. */
			} );

			const command = editor.commands.get( 'saveIndicator' );
			view.bind( 'isEnabled' ).to( command! );
			// @ts-ignore
			view.bind( 'isVisible' ).to( command!, 'isLoading' );
			return view;
		} );
	}
}

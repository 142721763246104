import { Command } from '@ckeditor/ckeditor5-core';
import { ObservableMixin, mix } from '@ckeditor/ckeditor5-utils';

class SaveIndicatorCommand extends Command {
	constructor( editor: any ) {
		super( editor );
		// @ts-ignore
		this.set( 'isLoading', false );
	}

	public override execute( params: any ): void {
		// @ts-ignore
		this.isLoading = params.isLoading;
	}
}

// Make the command Observable.
mix( SaveIndicatorCommand, ObservableMixin );

export default SaveIndicatorCommand;

import { Plugin } from '@ckeditor/ckeditor5-core';

import GenerateWithAIUI from './generatewithaiui';
import GenerateWithAIEditing from './generatewithaiediting';

export default class GenerateWithAI extends Plugin {
	public static get requires() {
		return [ GenerateWithAIEditing, GenerateWithAIUI ];
	}

	public static get pluginName(): string { return 'GenerateWithAI'; }
}

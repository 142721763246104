import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';

export default class DeleteBlockUI extends Plugin {
	public init(): void {
		const editor = this.editor;
		// eslint-disable-next-line max-len
		const removeIconSvg = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.16 19.673L19.228 5.78998C18.0739 5.6155 16.9138 5.48308 15.75 5.39298C13.2537 5.20006 10.7463 5.20006 8.25 5.39298C7.08624 5.48308 5.92614 5.6155 4.772 5.78998L5.84 19.673C5.88359 20.2382 6.13894 20.7661 6.55498 21.1512C6.97103 21.5363 7.5171 21.7501 8.084 21.75H15.916C16.4829 21.7501 17.029 21.5363 17.445 21.1512C17.8611 20.7661 18.1164 20.2382 18.16 19.673Z" fill="transparent" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.25 5.95603C19.91 5.89703 19.57 5.84203 19.228 5.79003C18.0739 5.61555 16.9138 5.48313 15.75 5.39303M15.75 5.39303V4.47703C15.75 3.29703 14.84 2.31303 13.66 2.27603C12.5536 2.24067 11.4464 2.24067 10.34 2.27603C9.16 2.31303 8.25 3.29803 8.25 4.47703V5.39303M15.75 5.39303C13.2537 5.20011 10.7463 5.20011 8.25 5.39303M3.75 5.95503C4.09 5.89603 4.43 5.84103 4.772 5.79003C5.92613 5.61555 7.08623 5.48313 8.25 5.39303" fill="transparent" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.74 9L14.394 18M9.606 18L9.26 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';

		editor.ui.componentFactory.add( 'deleteBlock', ( locale: any ) => {
			const view = new ButtonView( locale );
			view.set( {
				tooltip: 'Remove Text Block',
				label: 'Remove Text Block',
				isEnabled: true,
				isVisible: true,
				withText: false,
				icon: removeIconSvg
			} );

			// Event fired when the 'execute' event is fired.
			view.on( 'execute', () => {
				// @ts-ignore
				const { onDeleteBlockButtonPressed } = editor.config._config.extraContext;
				onDeleteBlockButtonPressed();
			} );

			const command = editor.commands.get( 'deleteBlock' );
			view.bind( 'isEnabled' ).to( command! );
			// @ts-ignore
			view.bind( 'isVisible' ).to( command!, 'isVisible' );
			return view;
		} );
	}
}

function MentionCustomization( editor: any ): void {
	// The upcast converter will convert view <span class="mention" data-user-id="">
	// elements to the model 'mention' text attribute.
	editor.conversion.for( 'upcast' ).elementToAttribute( {
		view: {
			name: 'span',
			key: 'data-mention',
			classes: 'mention',
			attributes: {
				'data-user-id': true
			}
		},
		model: {
			key: 'mention',
			value: ( viewItem: any ) => {
				return editor.plugins.get( 'Mention' ).toMentionAttribute( viewItem, {
					userId: viewItem.getAttribute( 'data-user-id' )
				} as any );
			}
		},
		converterPriority: 'high'
	} );

	// Downcast the model 'mention' text attribute to a view <a> element.
	editor.conversion.for( 'downcast' ).attributeToElement( {
		model: 'mention',
		view: ( modelAttributeValue: any, { writer }: { writer: any } ) => {
			// Do not convert empty attributes (lack of value means no mention).
			if ( !modelAttributeValue ) {
				return;
			}

			return writer.createAttributeElement( 'span', {
				class: 'mention',
				'data-mention': modelAttributeValue.id,
				'data-user-id': modelAttributeValue.userId
			}, {
				priority: 20,
				id: modelAttributeValue.uid
			} );
		},
		converterPriority: 'high'
	} );
}

export default MentionCustomization;

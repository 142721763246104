import { Plugin } from '@ckeditor/ckeditor5-core';

import DeleteBlockUI from './deleteblockui';
import DeleteBlockEditing from './deleteblockediting';

export default class DeleteBlock extends Plugin {
	// @ts-ignore
	public static get requires() {
		return [ DeleteBlockEditing, DeleteBlockUI ];
	}

	// @ts-ignore
	public static get pluginName(): string { return 'DeleteBlock'; }
}

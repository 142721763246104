import { Plugin } from '@ckeditor/ckeditor5-core';
import SaveIndicatorCommand from './saveindicatorcommand';

export default class SaveIndicatorEditing extends Plugin {
	// @ts-ignore
	public static get requires() {
		return [];
	}

	public init(): void {
		this.editor.commands.add( 'saveIndicator', new SaveIndicatorCommand( this.editor ) );
	}
}

import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';

export default class GenerateWithAIUI extends Plugin {
	public init(): void {
		const editor = this.editor;
		/* eslint-disable max-len */
		const generateIconSVG = `
		<svg width="62" height="28" viewBox="0 0 62 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="28" height="28" rx="4" fill="#DF2780"/>
			<path d="M13.501 24C13.2509 24.0013 13.0063 23.9261 12.7999 23.7847C12.5936 23.6432 12.4353 23.4422 12.3463 23.2084C11.7783 21.7313 11.3189 18.9305 10.196 17.8075C9.07256 16.6841 6.27134 16.2243 4.79366 15.6557C4.56012 15.5661 4.35924 15.4078 4.21755 15.2016C4.07585 14.9955 4 14.7512 4 14.501C4 14.2509 4.07585 14.0066 4.21755 13.8004C4.35924 13.5943 4.56012 13.4359 4.79366 13.3463C6.27079 12.7783 9.07155 12.319 10.1945 11.196C11.3179 10.0726 11.7778 7.27133 12.3463 5.79366C12.4359 5.56012 12.5943 5.35924 12.8004 5.21755C13.0066 5.07585 13.2509 5 13.501 5C13.7512 5 13.9954 5.07585 14.2016 5.21755C14.4078 5.35924 14.5661 5.56012 14.6557 5.79366C15.2238 7.27079 15.6831 10.0716 16.8061 11.1945C17.9236 12.3121 20.7074 12.7689 22.1772 13.3344C22.4203 13.4245 22.6297 13.5873 22.7769 13.8007C22.9241 14.014 23.002 14.2676 23 14.5268C22.9962 14.7726 22.9187 15.0116 22.7776 15.2128C22.6365 15.4141 22.4382 15.5684 22.2084 15.6557C20.7312 16.2238 17.9305 16.6831 16.8075 17.8061C15.6841 18.9295 15.2242 21.7307 14.6557 23.2084C14.5667 23.4422 14.4085 23.6432 14.2021 23.7847C13.9958 23.9261 13.7512 24.0013 13.501 24Z" fill="white"/>
			<path d="M21.5 10C21.3989 9.99997 21.3002 9.96931 21.2169 9.91208C21.1336 9.85484 21.0696 9.77372 21.0334 9.67938C20.8246 9.1367 20.6771 8.15104 20.2629 7.73698C19.8636 7.33772 18.847 7.16924 18.3203 6.96664C18.226 6.93032 18.1449 6.86631 18.0878 6.78303C18.0306 6.69975 18 6.6011 18 6.50009C18 6.39908 18.0306 6.30044 18.0878 6.21716C18.1449 6.13388 18.226 6.06986 18.3203 6.03354C18.847 5.83095 19.8636 5.66247 20.2629 5.26321C20.6597 4.86649 20.8268 3.85717 21.0281 3.33393C21.0604 3.24682 21.1158 3.1701 21.1883 3.11194C21.2607 3.05377 21.3476 3.01634 21.4397 3.00362C21.5503 2.99024 21.6622 3.01417 21.7577 3.07163C21.8532 3.1291 21.9267 3.21679 21.9666 3.3208C22.1692 3.84743 22.3378 4.86396 22.7371 5.26321C23.1364 5.66247 24.153 5.83095 24.6797 6.03354C24.774 6.06986 24.8551 6.13388 24.9122 6.21716C24.9694 6.30044 25 6.39908 25 6.50009C25 6.6011 24.9694 6.69975 24.9122 6.78303C24.8551 6.86631 24.774 6.93032 24.6797 6.96664C24.153 7.16924 23.1364 7.33772 22.7371 7.73698C22.3378 8.13623 22.1692 9.15276 21.9666 9.67938C21.9304 9.77372 21.8664 9.85484 21.7831 9.91208C21.6998 9.96931 21.6011 9.99997 21.5 10Z" fill="white"/>
			<path d="M55.1636 8.72727H53.4761L56.5485 0H58.5002L61.5769 8.72727H59.8894L57.5585 1.78977H57.4903L55.1636 8.72727ZM55.219 5.3054H59.8212V6.57528H55.219V5.3054Z" fill="#DF2780"/>
			<path d="M46.7925 1.32528V0H53.7555V1.32528H51.0581V8.72727H49.4899V1.32528H46.7925Z" fill="#DF2780"/>
			<path d="M39.8867 8.72727V0H45.5629V1.32528H41.4677V3.6946H45.2688V5.01989H41.4677V7.40199H45.5969V8.72727H39.8867Z" fill="#DF2780"/>
			<path d="M32 8.72727V0H35.3409C35.9716 0 36.4957 0.0994318 36.9134 0.298295C37.3338 0.494318 37.6477 0.762784 37.8551 1.10369C38.0653 1.4446 38.1705 1.83097 38.1705 2.26278C38.1705 2.6179 38.1023 2.92188 37.9659 3.17472C37.8295 3.42472 37.6463 3.62784 37.4162 3.78409C37.1861 3.94034 36.929 4.05256 36.6449 4.12074V4.20597C36.9545 4.22301 37.2514 4.31818 37.5355 4.49148C37.8224 4.66193 38.0568 4.90341 38.2386 5.21591C38.4205 5.52841 38.5114 5.90625 38.5114 6.34943C38.5114 6.80114 38.402 7.20739 38.1832 7.56818C37.9645 7.92614 37.6349 8.20881 37.1946 8.41619C36.7543 8.62358 36.2003 8.72727 35.5327 8.72727H32ZM33.581 7.40625H35.2812C35.8551 7.40625 36.2685 7.29688 36.5213 7.07812C36.777 6.85653 36.9048 6.57244 36.9048 6.22585C36.9048 5.96733 36.8409 5.73438 36.7131 5.52699C36.5852 5.31676 36.4034 5.15199 36.1676 5.03267C35.9318 4.91051 35.6506 4.84943 35.3239 4.84943H33.581V7.40625ZM33.581 3.71165H35.1449C35.4176 3.71165 35.6634 3.66193 35.8821 3.5625C36.1009 3.46023 36.2727 3.31676 36.3977 3.1321C36.5256 2.9446 36.5895 2.72301 36.5895 2.46733C36.5895 2.12926 36.4702 1.85085 36.2315 1.6321C35.9957 1.41335 35.6449 1.30398 35.179 1.30398H33.581V3.71165Z" fill="#DF2780"/>
		</svg>`;

		editor.ui.componentFactory.add( 'generateWithAI', ( locale: any ) => {
			const view = new ButtonView( locale );
			view.set( {
				tooltip: 'Generate Text with AI',
				isEnabled: true,
				isVisible: true,
				withText: false,
				icon: generateIconSVG,
				class: 'ck-button_generateWithAI'
			} );

			// Event fired when the 'execute' event is fired.
			view.on( 'execute', () => {
				// @ts-ignore
				const { onGenerateWithAIButtonPressed } = editor.config._config.extraContext;
				onGenerateWithAIButtonPressed();
			} );

			const command = editor.commands.get( 'generateWithAI' );
			view.bind( 'isEnabled' ).to( command! );
			// @ts-ignore
			view.bind( 'isVisible' ).to( command!, 'isVisible' );
			return view;
		} );
	}
}

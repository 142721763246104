import { Plugin } from '@ckeditor/ckeditor5-core';
import DeleteBlockCommand from './deleteblockcommand';

export default class DeleteBlockEditing extends Plugin {
	// @ts-ignore
	public static get requires() {
		return [];
	}

	public init(): void {
		this.editor.commands.add( 'deleteBlock', new DeleteBlockCommand( this.editor ) );
	}
}

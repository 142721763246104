import { Plugin } from '@ckeditor/ckeditor5-core';
import GenerateWithAICommand from './generatewithaicommand';

export default class GenerateWithAIEditing extends Plugin {
	// @ts-ignore
	public static get requires() {
		return [];
	}

	public init(): void {
		this.editor.commands.add( 'generateWithAI', new GenerateWithAICommand( this.editor ) );
	}
}

import { Plugin } from '@ckeditor/ckeditor5-core';
import SaveIndicatorUI from './saveindicatorui';
import SaveIndicatorEditing from './saveindicatorediting';

export default class SaveIndicator extends Plugin {
	// @ts-ignore
	public static get requires() {
		return [ SaveIndicatorEditing, SaveIndicatorUI ];
	}

	// @ts-ignore
	public static get pluginName(): string { return 'SaveIndicator'; }
}
